import LegalHeader from "./LegalHeader";
import './legalPages.scss';
import {useContext} from "react";
import {CookiesContext} from "../App";

export const CookiesPage = () => {
    const {cookiesConsent, acceptCookies, denyCookies} = useContext(CookiesContext);

    return (
        <div className='legal-page'>
            <LegalHeader/>

            <div className='legal-content-container'>
                <h1>
                    COOKIES POLICY
                </h1>
                <h3>
                    Last updated on: August 31st, 2022
                </h3>
                <br/> <br/>
                <div className='legal-content'>
                    <section>
                        <p>
                            In order to offer an attractive aspect of our website and allow the use of certain
                            functions, we
                            use cookies on various pages of our website available at the following URL: <a
                            href='/'><i> https://www.oncruiter.no</i></a> and/or its subdomains <b>("Website")</b>. The
                            purpose of this cookies policy <b>("Cookies Policy")</b> is to inform the users of our
                            Website
                            on how we use cookies on the Website. <b>ONCRUITER AS</b>, a legal entity registered in
                            Norway,
                            with its registered office in Bureisergrenda 170, 2365 Åsmarka, Norway, registered with the
                            Register of Business Enterprises from Norway under no. 924 541 512 <b>("we", “us” or
                            “Company”)</b>, reserves the right to change the provisions of this Cookies Policy from time
                            to
                            time so that it remains constantly updated in accordance with the applicable law, without
                            prior
                            notice. If we make changes to this Cookies Policy, we will make the updated version
                            available on
                            the Website and update the "Last updated on" section. We encourage you to periodically check
                            this Cookies Policy to ensure that you are informed of the cookies that will be used on our
                            Website. Any change to the Cookies Policy will apply on the date it is made, will apply from
                            the
                            date it was made, except for the changes that require your prior consent which will apply
                            from
                            the date you will express your consent. This Cookies Policy is completed with our Privacy
                            Policy.
                        </p>
                    </section>
                    <section>
                        <h4>1. What are cookies</h4>
                        <p>
                            Cookies (terminology includes "Internet cookies", "browser cookies", "HTTP cookies" or
                            simply "cookies") are small text files made up of letters and numbers that will be stored on
                            a user's device (computer, mobile device, or other devices) when they access the Internet. A
                            cookie is installed by request sent by a web server to a browser (e.g. Chrome, Safari,
                            Firefox, Microsoft Edge, etc) and is completely passive, in the sense that it does not
                            contain software, viruses or spyware and cannot access information on the hard drive of the
                            user’s disk.
                        </p>
                        <p>
                            Cookies provide the user with a pleasant experience of accessing and browsing a website by
                            offering features such as: language options, saving registration data, relevant advertising
                            or shopping cart.
                        </p>
                        <p>
                            As a rule, the use of cookies does not involve the processing of personal data and, in most
                            cases, does not personally identify users. However, if the use of cookies results in the
                            processing of personal data, the processing of personal data under the Privacy Policy
                            becomes applicable for that processing. For more details about cookies, please visit:
                            <a target="_blank" rel='external'
                               href='http://en.wikipedia.org/wiki/Cookie'> http://en.wikipedia.org/wiki/Cookie</a>
                        </p>
                    </section>
                    <section>
                        <h4>2. Types of cookies</h4>
                        <p>
                            First party cookies are cookies set by the website you’re visiting. Only that website can
                            read them. In addition, a website might potentially use external services, which also set
                            their own cookies, known as third-party cookies.
                        </p>
                        <p>
                            Most of these cookies used by us will be deleted from your hard drive immediately after your
                            browsing session (so-called session cookies). Other cookies remain stored on the hard drive
                            of the user’s device and allow us to recognize the device in the event of a subsequent visit
                            to our website (so-called persistent cookies). These are the cookies that allow us to make
                            the website more friendly, efficient and secure for the user.
                        </p>
                    </section>
                    <section>
                        <h4>3. The lifetime of cookies</h4>
                        <p>
                            The lifetime of a cookie can vary significantly, depending on the purpose for which it is
                            placed. As mentioned above, some cookies are used exclusively for a single session (session
                            cookies) and are deleted once the user leaves the website, while other cookies (persistent
                            cookies) are kept and reused each time when the user returns to the website. However,
                            cookies can be deleted at any time by adjusting the settings of the browser you use.
                        </p>
                    </section>
                    <section>
                        <h4>4. Cookies placed by third parties</h4>
                        <p>
                            In general, cookies can be installed by the administrator of the site accessed by the user
                            (so-called own cookies) or by other websites (so-called cookie modules installed by third
                            parties). Our Website may contain links to external sites that are not operated by us. If
                            you click on a third party link, you will be directed to that third party's site. We have no
                            control over, and assume no responsibility for the content, policies or practices of any
                            such third party sites, products or services. This Cookies Policy does not cover the use of
                            cookies by such third parties and for more information about those cookies, please access
                            the policies and terms of use of these third parties.
                        </p>
                    </section>
                    <section>
                        <h4>5. Restrict and delete cookies</h4>
                        <p>
                            If you wish, you can restrict or delete cookies using your browser settings. You can also
                            refuse only cookies that are not strictly necessary for the operation of the website.
                            However, we remind you that if cookie settings are restricted on your device, you will no
                            longer be able to use all features of our website. For example, disabling certain cookies
                            would no longer allow us to display certain types of content. To change your browser
                            settings, you can access the “Privacy” or “Security” sections of your browser.
                            Please note that third parties (including, for example, advertising networks and providers
                            of external services like web traffic analysis services) may also use cookies, over which we
                            have no control.
                        </p>
                        <p>
                            You can learn more about changing the settings by visiting the following link:
                            <a target="_blank" rel='external'
                               href="http://www.allaboutcookies.org/manage-cookies/"> http://www.allaboutcookies.org/manage-cookies/</a>
                        </p>
                    </section>
                    <section>
                        <h4>6. Categories of cookies used on the Website</h4>
                        <p>
                            According to the law, we can store cookies on your device if they are strictly necessary for
                            the operation of the Site. For all other types of cookies, we need your permission.
                        </p>
                        <p>
                            The cookies we usually use on our Website are classified as following:
                            <ul>
                                <li>
                                    <b>Strictly necessary cookies.</b> These cookies are necessary for the proper
                                    functioning of the website, and in their absence the website cannot be used
                                </li>
                                <li><b>Performance cookies.</b> These cookies collect information about how you use our
                                    website (for example, the pages you visit and the errors displayed).
                                </li>
                                <li><b>Analysis/Statistics cookies.</b> These cookies are used to collect aggregate
                                    information about the number of visitors and how they use the website.
                                </li>
                                <li>
                                    <b>Functional cookies.</b> These cookies allow basic functions such as browsing the
                                    pages of the website and access to secure areas of the website. The website cannot
                                    operate under normal conditions without these cookies.
                                </li>
                                <li>
                                    <b>Session cookies.</b> These cookies allow the user to connect to the website
                                    without entering their account data.
                                </li>
                            </ul>
                        </p>
                    </section>
                    <section>
                        <h4>7. Cookies used on the Website </h4>
                        <p>
                            We use cookies to optimize the website and personalize its content, as well as, among other
                            things, to analyze the traffic on the website and store your consent. The Website uses
                            different types of cookies. being constantly updated. Some cookies are placed by third party
                            services that appear on our pages. By law, we may store cookies on your device if they are
                            strictly necessary for the operation of the website. We need your permission for all other
                            types of cookies. You may, at any time, modify or withdraw your consent. In order to
                            exercise this right, you can send us your request by email, and we will make the changes
                            according to the request received. Also, a simple alternative is to simply delete all our
                            website cookies from your browser.
                        </p>
                    </section>
                    <section>
                        <h4>8. Contact</h4>
                        <p>
                            If you have any questions or concerns regarding this Cookies Policy or its implementation,
                            you can contact us at the following email address: <a href="mailto:post@oncruiter.no">post@oncruiter.no</a>
                        </p>
                    </section>
                </div>

                {cookiesConsent === 'true' &&
                    <button className='withdraw-button' onClick={denyCookies}>
                        WITHDRAW YOUR CONSENT
                    </button>
                }

                {cookiesConsent === 'false' &&
                    <button className='withdraw-button' onClick={acceptCookies}>
                        ACCEPT COOKIES
                    </button>
                }
            </div>
        </div>
    )
}

export default CookiesPage;