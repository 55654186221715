const no = {
    header: {
        about: 'Om',
        contact: 'Kontakt',
    },
    intro: {
        introText: 'Oncruiter hjelper ettertraktede kandidater over i en mer motiverende jobbhverdag',
        scrollDown: 'Rull ned for å finne ut mer',
    },
    description: {
        shortDescription: 'Å finne kompetente og motiverte kandidater er et tidkrevende arbeid, og det vi brenner for!',
        longDescriptionFirst: 'Å finne kompetente og motiverte kandidater er et tidkrevende arbeid - og det vi brenner for.',
        longDescriptionSecond: 'For å få kandidater interessert i nye muligheter må man bruke tid på å forstå deres situasjon og drivkraft, og i samarbeid legge grunnlaget for en mer motiverende jobbhverdag. Det er dette vi har spesialisert oss på. Vår unike tilnærming gjør at vi finner flere ettertraktede kandidater med en sterkere motivasjon for å bytte jobb – så våre kunder kan bruke mer tid på sin kjernevirksomhet.',
    },
    founder: {
        vision: '«Oncruiter vil være den foretrukne samarbeidspartneren for rekrutterere og kandidater i den digitale tidsalderen. Vi leder an i bruken av online verktøy og plattformer, og introduserte bl.a. “speed dates” på Skandinavias første online karrieremesser. Vår digitale tilnærming til kandidater i kombinasjon med vår “VIC (Very Important Candidate) behandling” gir kundene våre tilgang til ettertraktede kandidater på en tidsbesparende måte»'
    },
    contact: {
        contactUs: 'Kontakt oss',
    }
}

export default no;