import Intro from "./Intro";
import About from "./About";
import Contact from "./Contact";
import './home.scss';
import CookiesBanner from '../CookiesBanner/Index';

const Home = () => {
    return(
        <>
            <Intro/>
            <About/>
            <Contact/>
            <CookiesBanner />
        </>
    )
}

export default Home;