const en = {
    header: {
        about: 'About',
        contact: 'Contact',
    },
    intro: {
        introText: 'Oncruiter matches top candidates with exciting job opportunities.',
        scrollDown: 'Scroll down to find out more',
    },
    description: {
        shortDescription: 'Finding competent and motivated candidates is time-consuming - and our passion!',
        longDescriptionFirst: 'In order to get top candidates interested in new career opportunities, one needs to understand their current situation and inner drive.',
        longDescriptionSecond: 'On this basis we discuss what may be a more fulfilling career move. This competence and motivation focused dialog is what sets Oncruiter apart. Our unique approach allows us to find more attractive candidates with a stronger motivation to exchange jobs - so our customers can spend more time on their core business.',
    },
    founder: {
        vision: '«Oncruiter`s vision is to be a preferred partner for both recruiters and candidates in the age of digital recruiting. We are a pioneer in the use of online recruiting tools, including Scandinavia`s first online career fairs. Utilizing the digital opportunities in combination with our “VIC (very important candidate) treatment” allows our customers to find the right candidates in a time saving manner»'
    },
    contact: {
        contactUs: 'Contact us',
    }
}

export default en;