import bkc_large from '../images/bkg_large.png';
import {useContext} from "react";
import {LanguageContext} from "../App";
import Email from '../svg/email.svg';
import Phone from '../svg/phone.svg';
import './contact.scss';

const Contact = () => {
    const {translation} = useContext(LanguageContext);

    return (
        <div id="contact" className="contact-section" style={{ backgroundImage: `url('${bkc_large}')`}}>
            <div className="container-fluid">
                <div className="contact-container">
                    <h2>{translation.contact.contactUs}</h2>
                    <p>Bureisergrenda 170, 2365 Åsmarka<br/>Org nr: 924 541 512</p>
                    <p className='contact-item'>
                        <img src={Phone} alt={'phone'} style={{height: '30px', width: '50px'}}/>
                        <a href="tel:+4797563630">+47 97563630</a>
                    </p>
                    <p className="contact-item">
                        <img src={Email} alt={'email'} style={{height: '30px', width: '50px'}}/>
                        <a href="mailto:post@oncruiter.no">post@oncruiter.no</a>
                    </p>
                    <br/><br/><br/>
                    <div className='legal-links'>
                        <a href='/privacy-policy'>Privacy policy</a>
                        <a href='/cookies'>Cookies policy</a>
                        <a href='/terms-and-conditions'>Terms and conditions</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact;