import logo from "../images/logo.png";

import {LanguageContext} from "../App";
import {useContext} from "react";

const MenuMobile = ({closeMenu}) => {
    const {translation, language, setLanguageEn, setLanguageNo} = useContext(LanguageContext);
    return (
        <div className={'mobile-overlay'}>
            <div className={'mobile-menus-container'}>
                <div className={'mobile-menus'}>
                    <a onClick={closeMenu} className="scroll about" href="#about">{translation.header.about}</a>
                    <a onClick={closeMenu} href="#contact"
                       className="scroll contact contact-border">{translation.header.contact}</a>
                </div>
                <div className={'mobile-menus language-menus'}>
                    <a onClick={setLanguageEn}
                       className={`${language === 'en' ? 'highlight-language' : ''}`}>EN</a>
                    <span className={'separator'}>
                        |
                    </span>
                    <a onClick={setLanguageNo}
                       className={`${language === 'no' ? 'highlight-language' : ''}`}>NO</a>
                </div>
            </div>
        </div>
    )
}

export default MenuMobile;