import Home from "./Home";
import Header from "./Header";
import React from 'react';
import {
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import './css/bootstrap-theme.min.css';
import './css/bootstrap.min.css';
import './css/font-awesome.min.css';
import './css/app.scss';
import './css/style.css';
import {useLanguage} from "./Translations/useLanguage";
import PrivacyPolicyPage from "./legal/PrivacyPolicyPage";
import CookiesPage from "./legal/CookiesPage";
import TermsAndConditionsPage from "./legal/TermsAndConditionsPage";
import {useCookies} from "./CookiesBanner/useCookies";

export const LanguageContext = React.createContext(null);
export const CookiesContext = React.createContext(null);

function App() {
    const cookiesContext = useCookies();
    const languageContext = useLanguage();

    return (
        <CookiesContext.Provider value={cookiesContext}>
            <LanguageContext.Provider value={languageContext}>
                <Switch>
                    <Route exact path='/privacy-policy'>
                        <PrivacyPolicyPage/>
                    </Route>
                    <Route exact path='/cookies'>
                        <CookiesPage/>
                    </Route>
                    <Route exact path='/terms-and-conditions'>
                        <TermsAndConditionsPage/>
                    </Route>
                    <Route exact path='/'>

                        <div className="App" id='homepage'>
                            <Header/>
                            <Home/>
                        </div>
                    </Route>
                    <Redirect to='/'/>
                </Switch>
            </LanguageContext.Provider>
        </CookiesContext.Provider>
    );
}

export default App;
