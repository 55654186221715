import LegalHeader from "./LegalHeader";
import './legalPages.scss';
import bkc_large from "../images/bkg_large.png";

export const PrivacyPolicyPage = () => {
    return (
        <div className='legal-page' style={{backgroundImage: `url('${bkc_large}')`}}>
            <LegalHeader/>

            <div className='legal-content-container'>
                <h1>
                    PRIVACY POLICY
                </h1>
                <h3>
                    Last updated on: August 31st, 2022
                </h3>
                <br/> <br/>
                <div className='legal-content'>
                    <section>
                        <p>
                            Respecting your private life is one of our core commitments and therefore we pay the most
                            attention to your personal data processing in accordance with Regulation (EU) 2016/679 of
                            the
                            European
                            Parliament and of the Council of 27 April 2016 on the protection of natural persons with
                            regard
                            to the processing of personal data and on the free movement of such data. We are aware of
                            the
                            importance of maintaining the confidentiality, integrity and security of your personal data
                            <b> ("Personal Data")</b> and have written this privacy policy <b>("Privacy Policy") </b>to
                            explain how Personal Data is collected, stored, used and disclosed by <b>ONCRUITER AS</b>, a
                            legal
                            entity registered in Norway, with its registered office in Bureisergrenda 170, 2365 Åsmarka,
                            Norway,
                            registered with the Register of Business Enterprises from Norway under no. 924 541
                            512<b> ("we", “us” or “Company”)</b>, as a Data Controller, in connection with access to and
                            use of the
                            website available at the following URL: <a
                            href='/'><i>https://www.oncruiter.no</i></a> and/or its
                            subdomains<b>("Website")</b>. Whenever we are bound by the applicable law, we will ask for
                            your
                            free, informed, specific and unequivocal consent to the processing of Personal Data. By
                            expressing your consent, you agree that we may collect, use, disclose, process and transfer
                            Personal Data in accordance with this Privacy Policy.
                        </p>
                        <p>
                            We reserve the right to change the provisions of this Privacy Policy from time to time so
                            that
                            it remains constantly updated in accordance with the applicable law. If we make changes to
                            this
                            Privacy Policy, we will make the updated version available on the Website and we will update
                            the
                            "Last updated on" section. We encourage you to periodically review this Privacy Policy to
                            ensure
                            that you are informed on how your Personal Data will be used. Any change to the Privacy
                            Policy
                            will apply from the date it was made, except for the changes that require your prior consent
                            which will apply from the date you will express your consent.
                        </p>
                    </section>
                    <section>
                        <h4>1. Definitions.</h4>
                        <p>The terms listed below have the following meanings:</p>
                        <p><h4>Personal Data</h4>Data means information that relates to identifiable natural person who
                            can
                            be
                            identified, directly or indirectly, in particular by reference to an identifier such as a
                            name,
                            an identification number, location data, etc.
                        </p>
                        <p>
                            <b>Processing</b> means any operation or set of operations which is performed on Personal
                            Data,
                            such as
                            collection, recording, organization, structuring, storage, adaptation or destruction.
                        </p>
                        <p>
                            <b>Data Subject (or you)</b> is an identified or identifiable natural person who can be
                            identified, directly or indirectly, based on particular Personal Data.
                        </p>
                        <p>
                            <b>Applicable law</b> means the Regulation (EU) 2016/679 of the European Parliament and of
                            the
                            Council
                            of 27 April 2016 on the protection of natural persons with regard to the processing of
                            personal
                            data and on the free movement of such data ("GDPR") and the the Norwegian Personal Data Act
                            no.
                            38 from June 15th, 2018 which implements the General Data Protection Regulation.
                        </p>
                        <br/>
                    </section>
                    <section>
                        <h4>2. Personal Data processed and purposes of processing</h4>
                        <p>
                            We use your data only in accordance with the applicable legal provisions. This section
                            describes
                            the purposes for which Personal Data are collected and processed.
                        </p>
                        <p>We do not collect or process sensitive data, as defined by the GDPR in the category of
                            special
                            personal data.
                        </p>
                        <p>
                            On our Website, in addition to the processed data mentioned below, we may collect and store
                            information with the help of cookies and other similar technologies, in accordance with our
                            Cookies Policy.
                        </p>
                        <div className='indent-list'>
                            <h4>A. Website Navigation Data. </h4>
                            <p>The computer systems and software applications used to operate the Website generally
                                obtain
                                some Personal Data (so-called "log files"), the transmission of which is implicit in the
                                use
                                of Internet communication protocols. This information is not collected to be associated
                                with
                                identified persons, but, due to their nature, may allow the identification of users by
                                processing and integration with certain data stored by third parties. This information
                                includes the IP addresses, the date and time of each visitor request, the method used to
                                send the request to the server, the size of the file obtained in response, the browser
                                type,
                                language preference, referring site, as well as other parameters related to the user's
                                operating system. </p>
                            <p>
                                We use this data only to obtain anonymous statistical information on the use of our
                                Website
                                and to verify its proper functioning. Our purpose in collecting non-personally
                                identifying
                                information is to better understand how our visitors use our Website. From time to time,
                                we
                                may release non-personally identifying information in the aggregate, e.g., by publishing
                                a
                                report on trends in the usage of our Website.
                            </p>
                            <p>
                                The legal basis for this processing of your browsing data is our legitimate interest to
                                improve our Website, to manage server capacity and to keep our website error-free, as
                                well
                                as to enable us to comply with legal obligations regarding the security details of our
                                systems. (Article 6, para. (1), letter f) of GDPR)
                            </p>
                            <h4>B. Provision of services through the Website</h4>
                            <p>
                                To benefit from our services, we will process your Personal Data which, depending on
                                your
                                request, will include one or more of the following: name and surname, e-mail address,
                                phone
                                number, place of work/company/organization name, location. The refusal to provide this
                                data,
                                determines the impossibility, total or partial, to benefit from the services provided
                                through our Website.
                                The legal basis for the processing is the execution of the contract and the provision of
                                our
                                services through the Website. (Article 6, para. (1), letter b) of GDPR) Also, in some
                                situations, the legal basis for the processing consists in our legitimate interest to
                                defend
                                our commercial activity (Article 6, para. (1), letter f) of GDPR), ensuring that all the
                                measures we take will guarantee a balance between our interests and your fundamental
                                rights
                                and freedoms. Thus, our legitimate interest may be to take measures to prevent and
                                detect
                                fraudulent attempts, including the transmission of information to the competent public
                                authorities or measures to manage various other risks.
                            </p>
                            <h4>C. Selection of candidates</h4>
                            <p>
                                The Company may advertise the available vacancies for selection and recruitment,
                                positions
                                that are presented on our website, in our agencies, on specialized recruitment platforms
                                or
                                on social networks. If you decide to apply for one of the available positions, we will
                                process your personal data which may include all or some of the following: name,
                                surname,
                                age, sex, address, job title, current job, past jobs, current employer, telephone
                                number,
                                email address, LinkedIn profile and Url, diplomas/certificates, forms of education,
                                professional memberships, image, voice, preferences, interests, usage of our Website and
                                LinkedIn page, ID, passport, other data mentioned in your curriculum vitae or LinkedIn
                                profile, other data provided by you in the course of registering for and attending
                                events or
                                meetings.
                            </p>
                            <p>
                                The legal basis for this processing is the execution of a contract to which you are a
                                party
                                or to take action at your request before concluding a contract. (Article 6, para. (1),
                                letter b) of GDPR) The refusal to provide this data, determines the impossibility to
                                include
                                you in our selection and recruitment process.
                            </p>
                            <h4>D. Commercial communications, surveys and marketing analyzes </h4>
                            <p>
                                If on our website you may have the option to opt to receive commercial communications
                                via
                                e-mail (newsletters) in connection with the products and services we offer, in order to
                                improve them. If you choose to receive such commercial messages, we will use your name
                                and
                                surname, and email address. You may refuse to provide us with such data and to receive
                                commercial communications from us.
                            </p>
                            <p>
                                If you have given your consent and changed your mind later, or want to update it, you
                                can do
                                so at any time and at no cost, by unsubscribing from the email list based on the
                                unsubscribe
                                button included in the emails received from us, or by sending your request to the e-mail
                                address indicated in the Contact section below.
                            </p>
                            <p>
                                The legal basis for this processing is your freely expressed consent. (Article 6, para.
                                (1),
                                letter a) of GDPR)
                            </p>
                            <h4>E. Contacting users</h4>
                            <p>
                                When you contact us via the contact form available on our Website and/or your Website
                                account, we will process your name, surname and email address, as well as any
                                information
                                contained in the message sent.
                            </p>
                            <p>
                                The legal basis for this processing is the execution of a contract to which the data
                                subject
                                is a party or to take action based on the request of the data subject before concluding
                                a
                                contract (Article 6, para. (1), letter b) of GDPR). The legal basis may also be our
                                legitimate interest in communicating and providing the necessary support to the users.
                                (Article 6, para. (1), letter f) of GDPR)
                            </p>
                            <h4>F. Settlement of disputes</h4>
                            <p>
                                In order to resolve potential disputes related to you, we will process your name and
                                surname, address and Website member account details. The purpose of this processing is
                                to
                                protect our rights and legitimate interests and to support any claims before the courts
                                or
                                any other public authorities.
                            </p>
                            <p>
                                The legal basis of this processing is our legitimate interest to defend our rights or
                                legitimate interests or to support claims arising or closely related to the conclusion
                                or
                                performance of the contract or to exercise the right of defense in the event of
                                litigation
                                against us. (Article 6, para. (1), letter f) of GDPR).
                            </p>
                        </div>
                    </section>
                    <section>
                        <h4>3. Provision of Personal Data</h4>
                        <p>
                            You may refuse to provide certain Personal Data but, in such a case, you may not be able to
                            benefit from certain specific services described in this Privacy Policy. You may also
                            request to
                            change your originally provided option regarding your consent to provide certain Personal
                            Data,
                            in which case please send your request to the email address indicated in the Contact section
                            below.
                        </p>
                    </section>
                    <section>
                        <h4>4. Legal basis</h4>
                        <p>
                            The legal basis for processing Personal Data is established by Article 6 of GDPR on the
                            legality
                            of processing. The legal basis for processing differs depending on the actual situation and
                            the
                            Personal Data processed, as detailed in section 2 above. In the event that we process your
                            Personal Data on the basis of your consent, we will request your free, informed, specific
                            and
                            unequivocal consent to such processing. By expressing your consent, you agree that we may
                            collect, use, disclose, process and transfer Personal Data in accordance with this Policy.
                        </p>
                    </section>
                    <section>
                        <h4>5. Automatic processing of Personal Data </h4>
                        <p>
                            Your Personal Data will not be processed in order to generate decisions based solely on
                            automatic processing that would have legal effects on you or significantly affect you, in
                            the
                            sense of Article 22 para. (1) of GDPR.
                        </p>
                    </section>
                    <section>
                        <h4>6. Processing duration</h4>
                        <p>As a general rule, the processing duration of Personal Data is subject to the following:</p>
                        <ul>
                            <li>The website navigation data will be kept for a period of up to one year.</li>
                            <li>Personal Data collected in order to provide our services will be kept in accordance with
                                the
                                legal requirements.
                            </li>
                            <li>Personal Data collected for contacting users will be kept until your consent is
                                withdrawn.
                            </li>
                            <li>Personal Data collected for settlement of disputes will be kept until the expiration of
                                the
                                legal prescription date for filling a claim.
                            </li>
                        </ul>
                        <p>
                            In certain circumstances, we may retain Personal Data for longer periods of time, for
                            example if
                            we are required to do so in accordance with the legal, regulatory, tax or accounting
                            requirements, or to keep records of your relationship with us in the event of any complaints
                            or
                            if we reasonably believe that there is a prospect of litigation related to the processing of
                            Personal Data or your transactions.
                        </p>
                    </section>
                    <section>
                        <h4>7. Transfer of Personal Data</h4>
                        <p>
                            Personal Data is stored on our servers and on the servers of our contractual partners who
                            help
                            us to provide our services.
                        </p>
                        <p>We may transfer Personal Data, to the extent necessary, to the following categories of
                            recipients: group companies, contractual partners, subcontractors, companies offering
                            various
                            services to the Company, payment service providers, software or hardware product providers,
                            market research companies, marketing companies, public authorities, courts or arbitral
                            tribunals, as well as competent authorities to investigate the commission of criminal
                            offenses.
                        </p>
                        <p>
                            These recipients may be located in the European Union and / or the Economic European Area,
                            as
                            well as outside the European Union and European Economic Area, including countries that are
                            not
                            recognized as providing an adequate level of protection, in which case the transfer of
                            Personal
                            Data is carried out only if there are adequate guarantees, in accordance with the GDPR (such
                            as
                            the new set of standard contractual clauses adopted by the European Commission for the
                            transfer
                            of personal data to non-EU regions) and after assuring that a Data Processing Agreement was
                            concluded between us and the data recipient.
                        </p>
                        <p>
                            You can request a list of recipients from these third countries, as well as a copy of the
                            agreed
                            provisions that ensure an adequate level of protection of Personal Data, by sending an email
                            to
                            the address indicated in the Contact section below.
                        </p>
                    </section>
                    <section>
                        <h4>8. Personal Data security</h4>
                        <p>Personal Data security is important to us. Therefore, the data will be processed by using the
                            principle of minimizing data collection and by applying appropriate technical and
                            organizational
                            measures to protect them, such as limiting access to Personal Data, encrypting or
                            anonymizing
                            them, storage them on secure media. However, despite our best efforts, we cannot always
                            guarantee the effectiveness of the security measures implemented, and therefore we cannot
                            guarantee the security of Personal Data at any time.</p>
                    </section>
                    <section>
                        <h4>9. Your rights regarding the processing of Personal Data</h4>
                        <p>In accordance with the GDPR, you have the following rights:</p>
                        <ul>
                            <li>
                                <b>Right of access: </b>You have the right to obtain confirmation from us that your
                                Personal
                                Data is processed by us, as well as information about the specifics of the processing.
                            </li>
                            <li>
                                <b>Right to rectification:</b> You may request the rectification of your Personal Data,
                                provided
                                that
                                the applicable legal requirements are complied with. In the event of errors, after
                                notification,
                                we will immediately correct the Personal Data.
                            </li>
                            <li>
                                <b>Right of deletion:</b> In certain cases, you may request the deletion of Personal
                                Data,
                                such
                                as when: (i) they are no longer necessary for the purposes for which we collected and
                                processed
                                them; (ii) you have withdrawn your consent to the processing of Personal Data and we can
                                no
                                longer process Personal Data on other legal basis; (iii) Personal Data are processed
                                against
                                the
                                law; (iv) you exercise a legal right to object. We are under no obligation to comply
                                with
                                your
                                deletion request if the processing of Personal Data is necessary to comply with a legal
                                obligation, or to establish, exercise or defend a right in court. Also, there are other
                                legal
                                circumstances in which we are not obliged to comply with this request to delete Personal
                                Data.
                            </li>
                            <li>
                                <b>Right to restrict the processing:</b> You may request that we restrict the processing
                                of
                                your
                                Personal Data in the following situations: (i) if you challenge the correctness of the
                                Personal
                                Data, for a period that allows us to verify the accuracy of the Personal Data in
                                question;
                                (ii)
                                if the processing is illegal, and you object to the deletion of the Personal Data,
                                requesting in
                                return the restriction of their use; (iii) if we no longer need the Personal Data for
                                the
                                purpose of processing, but you request it from us for an action in court; (iv) if you
                                objected
                                to the processing, for the period during which it is verified whether our legitimate
                                rights
                                as a
                                Data Controller prevail over those of the data subject. We may continue to use Personal
                                Data
                                following a request for a restriction, if: (i) we have your consent; (ii) we need to
                                establish,
                                exercise or ensure the defense of a right in court; or (iii) we need to protect the
                                rights
                                of
                                another natural or legal person.
                            </li>
                            <li>
                                <b>Right to data portability:</b> To the extent that Personal Data is processed based on
                                your
                                consent or for the execution of the contract and the processing is done by automatic
                                means,
                                you have the right to have your Personal Data provided to you in a structured format,
                                currently used and which can be read automatically, and you have the right to transmit
                                this
                                Personal Data to another Data Controller. This right is subject to not cause a prejudice
                                to
                                the rights and freedoms of other people.
                            </li>
                            <li>
                                <b>Right to object:</b> In certain situations, such as when we process your Personal
                                Data
                                based on
                                a legitimate interest, you have the right to oppose the processing of Personal Data by
                                us.
                                In the event of unjustified opposition, we are entitled to further process the Personal
                                Data.
                            </li>
                            <li>
                                <b>Right to object to receive commercial messages:</b> You may also object to the
                                processing
                                of your Personal Data for the purpose of sending commercial messages.
                            </li>
                            <li>
                                <b>Right not to be subject to automated individual decisions:</b> If the applicable
                                legal
                                provisions are complied with, you have the right not to be subject to a decision based
                                solely on automatic processing, including profiling, which has legal effects on you or
                                affects you in a similar significant measure. This right does not apply if the decision:
                                (i)
                                is necessary for the conclusion or performance of a contract between you and us; (ii) is
                                authorized by law to provide adequate safeguards for your rights and freedoms; (iii) is
                                based on your explicit consent.
                            </li>
                            <li>
                                <b>Revocation of consent:</b> To the extent that we process your Personal Data based on
                                your
                                consent, you may revoke the consent at any time, without prejudice to the lawfulness of
                                the
                                consent-based processing prior to its withdrawal. You can revoke the prior agreement at
                                any
                                time and free of charge, either by sending us a request to the email address indicated
                                in
                                the Contact section below or, in the case of the newsletter, by using the "unsubscribe"
                                option that is included in each commercial electronic communication.
                            </li>
                            <li>
                                <b>
                                    Right to contact the supervisory authority:</b> You have the right to file a
                                complaint
                                with the
                                competent Supervisory Authority, in connection with any violation of your rights
                                regarding
                                the Processing of your Personal Data. The contact details for the Supervisory Authority
                                are
                                the following: <b>Datatilsynet, P.O. Box 458 Sentrum, NO-0105
                                Oslo,
                                <a target="_blank" rel='external'
                                   href="https://www.datatilsynet.no/"
                                > www.datatilsynet.no</a>
                            </b>
                            </li>
                        </ul>
                        <p>
                            However, if you have any dissatisfaction regarding the processing of your Personal Data, we
                            hope
                            that you will contact us in order to settle amicably your request.
                            To exercise the above rights, please contact us by email to the address indicated in the
                            Contact
                            section below.
                        </p>
                    </section>
                    <section>
                        <h4>10. Third-party content and links </h4>
                        <p>
                            Our Website may contain links to other websites, services, and web addresses. This Privacy
                            Policy applies only to our Website, not those external websites, services and web addresses
                            that
                            we link to. Those websites, services and web addresses have their own privacy policies and
                            we
                            are not responsible for these external websites and services and their privacy policies and
                            practices, as well as their compliance with applicable data protection laws. In addition, if
                            you
                            link to our Website from an external site, we cannot be responsible for the privacy policies
                            and
                            practices of the owners and operators of that external website and recommend that you check
                            the
                            privacy policy of that external website.
                        </p>
                    </section>
                    <section>
                        <h4>11. Age Limitation</h4>
                        <p>
                            Our Website is generally not for users who are under 18 years old. We do not knowingly
                            process
                            any Personal Data from persons under 18 years of age, and any such data shall be immediately
                            deleted upon detection, unless (1) processing of Personal Data of persons under 18 years of
                            age
                            is explicitly allowed by the national personal data protection law applicable to such
                            person’s
                            Personal Data, or (2) person under 18 years of age consented to processing of its Personal
                            Data
                            or, where lawfully required, the consent was given by the tutor of the person under 18
                            years.
                        </p>
                    </section>
                    <section>
                        <h4>12. Contact</h4>
                        <p>
                            If you have any questions or concerns regarding the Privacy Policy or its implementation,
                            you
                            can contact us at the following email address: <a
                            href="mailto:post@oncruiter.no">post@oncruiter.no</a>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicyPage;