import logo from "../images/logo.png";
import {useCookies} from "./useCookies";
import './cookiesBanner.scss';

const CookiesBanner = () => {
    const {showBanner, acceptCookies, denyCookies} = useCookies();

    if (showBanner) {
        return (
            <div className='cookies-banner'>
                <img src={logo} alt="Logo Oncruiter" style={{height: '83px'}}/>
                <div className='cookies-text'>
                    <h2>This website uses cookies</h2>
                    <p>
                        To find out more about what are those, why and how we use the, please consult our
                        <a href='/cookies'> COOKIES POLICY</a>
                    </p>
                </div>
                <div className='cookies-buttons'>
                    <button onClick={acceptCookies}>ALLOW</button>
                    <button onClick={denyCookies}>DENY</button>
                </div>
            </div>
        )
    }

    return null;
}

export default CookiesBanner;