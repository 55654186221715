import {useEffect, useState} from "react";
import en from "./english";
import no from "./norwegian";
import {useCookies} from "../CookiesBanner/useCookies";

export const useLanguage = () => {
    const [language, setLanguage] = useState('en');
    const [translation, setTranslation] = useState(en);

    const {cookiesConsent} = useCookies();

    useEffect(() => {
        if(cookiesConsent === 'true') {
            const preferredLanguage = localStorage.getItem('preferredLanguage');

            if(preferredLanguage) {
                setLanguage(preferredLanguage);
            }
        }
    }, [cookiesConsent])

    useEffect(()=> {
        if(language === 'en') {
            setTranslation(en);
        } else setTranslation(no);
    }, [language])

    const setLanguageEn = () => {
        setLanguage('en');
        if(cookiesConsent === 'true') {
            localStorage.setItem("preferredLanguage", "en");
        }
    }

    const setLanguageNo = () => {
        setLanguage('no');
        if(cookiesConsent === 'true') {
            localStorage.setItem("preferredLanguage", "no");
        }
    }

    return {
        setLanguageEn,
        setLanguageNo,
        language,
        translation,
    }
}