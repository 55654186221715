import './intro.scss';
import background from '../images/background.png';
import {useContext} from "react";
import {LanguageContext} from "../App";
import Arrow from '../svg/arrow-down.svg';

const Intro = () => {
    const {translation} = useContext(LanguageContext);

    return (
        <div className="homepage" style={{ background: `linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)), url(${background})`, backgroundSize: "cover"}}>
            <div className="container-fluid">
                <div className="homepage-description">
                    <h1>{translation.intro.introText}</h1>
                </div>
                <a href="#about" className="scroll-down">
                    <span>{translation.intro.scrollDown}</span>
                    <img src={Arrow} alt={'arrow'} style={{height: '30px', width: '50px'}}/>
                </a>
            </div>
        </div>
    )
}

export default Intro;