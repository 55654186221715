import LegalHeader from "./LegalHeader";
import './legalPages.scss';

export const TermsAndConditionsPage = () => {
    return (
        <div className='legal-page'>
            <LegalHeader/>

            <div className='legal-content-container'>
                <h1>
                    COOKIES POLICY
                </h1>
                <h3>
                    Last updated on: August 31st, 2022
                </h3>
                <br/> <br/>
                <div className='legal-content'>
                    <section>
                        <p>
                            These Terms & Conditions (“Terms”) constitute a legally binding agreement made between you,
                            whether personally or on behalf of an entity ("You") and <b>ONCRUITER AS</b>, a legal entity
                            registered in Norway, with its registered office in Bureisergrenda 170, 2365 Åsmarka,
                            Norway, registered with the Register of Business Enterprises from Norway under no. 924 541
                            512 ("We", “Us”, “Company”), concerning your access to and use of the
                            <a href='/'><i> https://www.oncruiter.no </i></a>
                            website (“Site”) as well as including all products and services, information or software,
                            made available through the Site. By using the Site, you understand and accept the fact that
                            you agree that you have read, understood, and agreed to be bound by all of these Terms.
                        </p>
                    </section>
                    <section>
                        <h4>TERMS OF USE</h4>
                        <p>
                            In order to use the Site, you must have the legal age of majority in your country of
                            residence, as the use of the Site is permitted only to those above the age of 18. By using
                            the Site, you testify that you are not a minor in the jurisdiction in which you reside, that
                            you have the legal capacity, and you agree to comply with these Terms. Please read the Terms
                            carefully before you start to use the Site, as the Site is offered to you conditional upon
                            your acceptance of the Terms, the Privacy Policy, Cookies Policy and any disclaimers or
                            notices contained in them and the Site itself. By using the Site, you indicate that you
                            accept the Terms, and that you agree to respect them. If you do not agree to the Terms, we
                            understand and accept your choice but, in this case, we kindly ask you to refrain from using
                            the Site. We reserve the right to change the provisions of the Terms from time to time so
                            that it remains constantly updated in accordance with applicable law. If we make changes to
                            the Terms, we will make the updated version available on the Site and update the date
                            mentioned in the “Last updated” paragraph. We encourage you to periodically review the Terms
                            to ensure that you take notice of any changes, as they are binding on you. Any changes to
                            the Terms will apply only for the future, starting with the changing date. Some of the
                            provisions contained in these Terms may also be superseded by provisions or notices
                            published elsewhere on the Site, usually as a disclaimer but not only. By continuing to use
                            or access the Site after the Terms’ update come into effect, you agree to be bound by the
                            updated Terms. These Terms supersede and replace any prior version of the Terms & Conditions
                            agreed upon between you and the Company.
                        </p>
                        <p>
                            Nothing contained in these Terms is in derogation of our right to comply with governmental,
                            court and law enforcement requests or requirements relating to your use of the Site or
                            information provided to or gathered by us with respect to such use. If any part of these
                            Terms is determined to be invalid or unenforceable under any applicable law, including, but
                            not limited to, the warranty disclaimers and liability limitations set out above, then the
                            invalid or unenforceable provision will be deemed superseded by a valid, enforceable
                            provision that most closely matches the intent of the original provision and the remainder
                            of these Terms will continue in effect. We may provide you with notices, including those
                            regarding changes to the Terms by email, postings on the Site, or other reasonable means now
                            known or developed in the future.
                        </p>
                    </section>
                    <section>
                        <h4>ACCESSING AND USING THE SITE</h4>
                        <p>
                            By using the Site, you understand and accept the fact that the access to the Site is
                            permitted on a temporary basis, and we reserve the right to withdraw or amend the legal
                            services we provide on the Site without any notice. Every now and then, due to maintenance
                            or for other reasons, we may restrict access to all visitors to some parts of the Site, or
                            the entire Site. We plan to update the Site regularly and may change the content at any
                            time. To our own discretion, we may suspend access to the Site, or close the Site
                            indefinitely. We will not be liable if for any reason the Site is unavailable at any time or
                            for any period. Any content, in part or as a full, on the Site may be out of date at any
                            given time, and we are under no obligation to update the content. Please be aware that all
                            arrangements necessary for you to have access to the Site and for ensuring any contact
                            details you provide us with are correct and up to date, are in your responsibility. Please
                            note that it’s your responsibility to ensure that all persons who access the Site through
                            your internet connection are aware, understand and comply with the Terms. As a condition of
                            your use of the Site, you will not use the Site for any purpose that is unlawful or
                            prohibited by these Terms or by the law. You will not access the Site through automated or
                            non-human means, whether through a bot, script, or otherwise. You warrant that all
                            registration information you submit will be true, accurate, current, and complete and you
                            will maintain the accuracy of such information and promptly update such registration
                            information as necessary. You will not use the Site in any manner which could damage,
                            disable, overburden or impair the Site, or interfere with any other party’s use and
                            enjoyment of the Site. The Site's content is for your personal use only and not for resale.
                            If you print, copy or download any part of the Site in breach of these Terms, your right to
                            use the Site will cease immediately and, at our option, you might be referred to court for
                            covering the damage caused to us and/or any other third-party.
                        </p>
                        <p>
                            You must not misuse the Site by knowingly introducing viruses, other material, which is
                            malicious or technologically harmful, or conduct any activity that could jeopardize the
                            Site’s stability. You must not attempt to gain unauthorized access to the Site, the server
                            on which the Site is hosted or any server, computer or database connected to the Site. By
                            breaching this provision, you would commit a criminal offence under the applicable law. We
                            will report any such breach to the relevant law enforcement authorities, and we will
                            cooperate with those authorities by disclosing your identity to them. In the event of such a
                            breach, your right to use the Site will cease immediately. Please note that we will not be
                            liable for any loss or damage caused to you by a viruses or other technologically harmful
                            material that may infect your computer equipment, computer programs, data or other
                            proprietary material due to your use of the Site or to your downloading of any material
                            posted on it, or on any website linked to it.
                        </p>
                        <p>
                            We reserve the right, but not the obligation, to: (a) monitor the Site for violations of
                            these Terms; (b) take appropriate legal action against anyone who, in our sole discretion,
                            violates the law or these Terms, including without limitation, reporting such user to law
                            enforcement authorities; (c) in our sole discretion and without limitation, refuse, restrict
                            access to, limit the availability of the Site to you, including blocking certain IP
                            addresses; (d) in our sole discretion and without limitation, notice, or liability, to
                            remove from the Site or otherwise disable all files and content that are excessive in size
                            or are in any way burdensome to our systems; and (e) otherwise manage the Site in a manner
                            designed to protect our rights and property and to facilitate the proper functioning of the
                            Site.
                        </p>
                    </section>
                    <section>
                        <h4>TERM AND TERMINATION</h4>
                        <p>
                            These Terms shall remain in full force and effect while you use the Site and, where
                            applicable, shall produce their effects for and/or against you after you use the Site in
                            certain situations described throughout these Terms. If we terminate or suspend your access
                            to the Site, you are prohibited from using the Site under your name, a fake or borrowed
                            name, or the name of any third party, even if you may be acting on behalf of the third
                            party. In addition to terminating or suspending your access to the Site, we reserve the
                            right to take appropriate legal action, including without limitation pursuing civil,
                            criminal, and injunctive redress if your actions while using the Site have caused or are
                            reasonable susceptible to cause any damage to the Company and/or any other third-party.
                        </p>
                    </section>
                    <section>
                        <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
                        <p>
                            By using the Site, you understand and accept the fact that the Company is the owner or the
                            licensee of all Intellectual Property Rights on the Site, and the material published on it.
                            For the purposes of these Terms, “Intellectual Property Rights” means any and all
                            intellectual property rights, whether registered or unregistered, including but not limited
                            to any domain names, URLs, design rights, copyright, brands, business names, product names,
                            logos, software rights, database rights, patents, trademarks, trade secrets, confidential
                            information, the legal forms, legal documents, letters, legal guides, articles and all other
                            content or information found on the Site (“Content”), or any similar rights in any
                            jurisdiction. All these are worldwide protected by copyright laws and treaties and are
                            reserved. Except obtaining a prior consent from the owner and providing the public with the
                            source of the Content and with the obligation to not use the material in a derogatory
                            manner, you must not: reproduce, modify, translate or create derivative works, sell,
                            license, sub-license, rent, lease, distribute, copy, publicly display, publish, adapt or
                            edit any Content. Please note that the names of any companies or products mentioned on the
                            Site may be the trademarks of their respective owners.
                        </p>
                        <p>
                            You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or
                            other information regarding the Site ("Submissions") provided by you to us are
                            non-confidential and shall become our sole property. We shall own exclusive rights,
                            including all intellectual property rights, and shall be entitled to the unrestricted use
                            and dissemination of these Submissions for any lawful purpose, commercial or otherwise,
                            without acknowledgment or compensation to you. You hereby waive all moral rights to any such
                            Submissions, and you hereby warrant that any such Submissions are original with you or that
                            you have the right to submit such Submissions. You agree there shall be no recourse against
                            us for any alleged or actual infringement or misappropriation of any proprietary right in
                            your Submissions.
                        </p>
                    </section>
                    <section>
                        <h4>RELATION WITH THE USER</h4>
                        <p>
                            By using the Site, you understand and accept the fact that any Content provided on the Site
                            does not constitute a professional advice and should not be relied upon as such. We
                            therefore disclaim all liability and responsibility arising from any reliance placed on such
                            Content. We reserve the right, in our own discretion, to review and remove materials posted
                            on the Site, in whole or in part. We cannot guarantee that information and / or documents
                            are correct, current or up-to-date, or suitable for every situation.
                        </p>
                    </section>
                    <section>
                        <h4>SERVICES</h4>
                        <p>
                            The Company is specialized in providing recruitment search services and activities for its
                            clients, third-party entities. We offer our Site's users the opportunity to become potential
                            candidates for the jobs made available by our clients ("Services") We offer access to the
                            Site with no guarantees regarding entering into employment contracts or any other such
                            similar contracts with any third party. The Company reserves the right to update, change or
                            modify at any time the Services or functionalities offered on the Site and/or the right to
                            offer services different from those offered at the time of a user’s visit of the Site.
                        </p>
                    </section>
                    <section>
                        <h4>LIABILITY</h4>
                        <p>
                            By using the Site, you understand and accept the fact that we will use all our reasonable
                            efforts to maintain the Site and its Content accurate, please be aware that the Content on
                            the Site is provided without any guarantees, conditions or warranties as to its accuracy and
                            may include inaccuracies or typographical errors and also that we and/or suppliers may make
                            improvements and/or changes to the Site at any time. All Content is provided “as is” without
                            any warranty of any kind. Please note that the use of the Site is at your own risk and, to
                            the maximum extent permitted by applicable law, the Company and/or any of its
                            representatives shall not be liable for any indirect, incidental, special, consequential or
                            punitive damages, or any loss of profits or revenues, whether incurred directly or
                            indirectly, or any loss of data, use, good-will, or other intangible losses, resulting from
                            your access to the Site.
                        </p>
                        <p>
                            We comply with certain export controls and economic sanctions laws. Due to this, you, as
                            user of our Site you warrant that: (a) you are not located in, and you are not a national or
                            resident of, any country to which the United States, United Kingdom, European Union,
                            Australia or Canada has embargoed goods and/or services of the same type as the Services,
                            including without limitation, Cuba, Iran, North Korea, Syria or the Crimea region of
                            Ukraine; and (b) you are not a person or entity, or owned by, under the control of, or
                            affiliated with, a person or entity (i) that appears on the U.S. Office of Foreign Assets
                            Control's Specially Designated Nationals List, Foreign Sanctions Evaders List or Palestinian
                            Legislative Council List; the U.S. Department of State's Terrorist Exclusion List; the
                            Bureau of Industry and Security's Denied Persons, Entity or Unverified List; the
                            Consolidated List of Targets published by the U.K. HM Treasury; the Consolidated List
                            published by the A.U. Department of Foreign Affairs and Trade; (ii) that is subject to
                            sanctions in any other country; or (iii) that is engaged in the design, development or
                            production of nuclear, biological or chemical weapons, missiles or unmanned aerial vehicles.
                        </p>
                    </section>
                    <section>
                        <h4>INDEMNIFICATION</h4>
                        <p>
                            You agree to defend, indemnify, and hold the Company harmless, including our subsidiaries,
                            affiliates, and all of our respective officers, agents, partners, employees, and legal
                            representatives from and against any loss, damage, liability, claim, or demand, including
                            reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
                            (a) your use of the Site and/or the Services; (b) breach of these Terms; (c) any breach of
                            your representations and warranties set forth in these Terms; (d) your violation of the
                            rights of a third party, including but not limited to intellectual property rights; or (e)
                            any overt harmful act toward any other user of the Site and Company's clients with whom you
                            connected via the Site. Notwithstanding the foregoing, we reserve the right, at your
                            expense, to assume the exclusive defense and control of any matter for which you are
                            required to indemnify us, and you agree to cooperate, at your expense, with our defense of
                            such claims. We will use reasonable efforts to notify you of any such claim, action, or
                            proceeding which is subject to this indemnification upon becoming aware of it. You have the
                            right to prove that the Company incurred lesser damages than the claims made.
                        </p>
                    </section>
                    <section>
                        <h4>DATA PROTECTION</h4>
                        <p>
                            By using the Site, you understand and accept the fact that any personal information you
                            provide to us via the Site is governed by our Privacy Policy available on the Site and by
                            using the Site you consent to it and you warrant that all data provided by you is accurate.
                            The Company does not own any of the materials you provide to us via the contact form or
                            other electronics or non-electronics methods. However, by posting, uploading, inputting,
                            providing or submitting to us your materials you warrant and represent that you own or
                            control all of the rights to your materials and you grant the Company any necessary
                            sub-licensees and permission to use your materials in connection with the Site (to copy,
                            distribute, transmit, publicly display, publicly perform, reproduce, edit, translate and/or
                            reformat). Please note that at all times we reserve the right to disclose any information
                            necessary in order to abide by any applicable law, regulation, legal process or governmental
                            request.
                        </p>
                    </section>
                    <section>
                        <h4>LINKING TO AND FROM THE SITE</h4>
                        <p>
                            By using the Site, you understand and accept the fact that you may link to the Site,
                            provided you do so in a way that is fair and legal and does not damage our reputation or
                            take advantage of it, but you must not establish a link in such a way as to suggest any form
                            of association, approval or endorsement on our part where none exists. You must not
                            establish a link from any website that is not owned by you. The Site must not be framed on
                            any other site, nor may you create a link to any part of the Site without our explicit prior
                            consent.
                        </p>
                        <p>
                            The Site may contain links to third party resources and businesses on the Internet. Those
                            links are provided for your convenience to help you identify and locate other Internet
                            resources that may be of interest to you. We do not sponsor, and we are not legally
                            associated with any third party linked sites and we are not legally authorized to use any
                            trade name, registered trademark, logo or copyrighted material that may appear in the link.
                            We do not control, endorse or monitor the contents of any linked site and we hold no
                            responsibility about it or for any loss or damage that may arise from your use of such
                            linked site, and we will not be responsible for any act or omission of the third party.
                            Please be aware that these Terms do not cover your interaction with other linked sites and
                            you should carefully review the terms and conditions and privacy policies of any third party
                            sites if you use any service provided on a linked site.
                        </p>
                    </section>
                    <section>
                        <h4>ASSIGNMENT</h4>
                        <p>
                            By using the Site, you understand and accept the fact that you do not have the right to
                            assign or otherwise transfer your obligations under these Terms, or any right granted under
                            them, without our written consent, but we can freely transfer our rights under the Terms.
                        </p>
                    </section>
                    <section>
                        <h4>WAIVER</h4>
                        <p>
                            By using the Site, you understand and accept the fact that any failure by us to enforce or
                            exercise any provision of the Terms, or any related right, will not be a waiver of that
                            provision or right. Any rights not expressly granted in these Terms are reserved. If a
                            provision is found unenforceable, the remaining provisions of the Terms will remain in full
                            effect and an enforceable term will be substituted reflecting our intent as closely as
                            possible.
                        </p>
                    </section>
                    <section>
                        <h4>JURISDICTION AND APPLICABLE LAW</h4>
                        <p>
                            By using the Site, you understand and accept the fact that these Terms and any dispute or
                            claim arising out of or in connection with them or their subject matter or formation
                            (including non-contractual disputes or claims), or the Site, shall be governed by and
                            interpreted primarily in accordance with the laws of Norway and, in subsidiary, the European
                            Union. The courts from Oslo, Norway, will have exclusive jurisdiction over any claim arising
                            from, or related to, a visit to the Site or any dispute or claim arising out or in
                            connection with the Terms. By using the Site, you consent to this venue and waive any
                            objection in this regard. By using the Site you agree to be responsible for compliance with
                            all local and/or national laws from your place of citizenship and/or residency and/or the
                            place you access the Site.
                        </p>
                    </section>
                    <section>
                        <h4>YOUR RIGHTS </h4>
                        <p>
                            If you are a consumer client and have contracted the services of the Company via electronic
                            media, and you are unhappy with the service provided but have not been able to resolve your
                            complaint with us, you are entitled to submit a complaint to an “alternative dispute
                            resolution” (ADR) body via
                            <a target="_blank" rel='external' href='http://ec.europa.eu/odr'> http://ec.europa.eu/odr</a>. Please note
                            that the Company is lawfully
                            entitled not to submit to the ADR process.
                        </p>
                    </section>
                    <section>
                        <h4>YOUR CONCERNS</h4>
                        <p>
                            If you have any concerns about any material which appears on the Site, please contact us so
                            we can address your concern at: <a href="mailto:post@oncruiter.no">post@oncruiter.no</a>
                        </p>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default TermsAndConditionsPage;