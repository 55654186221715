import logo from '../images/logo.png';
import {useContext, useEffect, useState} from "react";
import {CookiesContext, LanguageContext} from "../App";
import './header.scss';
import MenuMobile from "./MenuMobile";
import {useCookies} from "../CookiesBanner/useCookies";

const Header = () => {
    const {translation, language, setLanguageEn, setLanguageNo} = useContext(LanguageContext);
    const [isMobile, setIsMobile] = useState();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {cookiesConsent} = useContext(CookiesContext);

    useEffect(() => {
        setIsMobile(window.innerWidth <= 767);
    }, []);

    const handleMenuButton = () => {
        if (isMenuOpen) {
            handleCloseMenu();
        } else {
            handleOpenMenu();
        }
    }

    const handleChangeLanguageEn = () => {
        setLanguageEn();
        handleCloseMenu();
    }

    const handleChangeLanguageNo = () => {
        setLanguageNo();
        handleCloseMenu();
    }

    const handleOpenMenu = () => {
        setIsMenuOpen(true);
        document.body.style.overflow = "hidden";
    }

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
        document.body.style.overflow = "auto";
    }


    return (
        <nav className="navbar navbar-default">
            <div className="container-fluid">
                <div className="navbar-header">
                    <button
                        onClick={handleMenuButton}
                        type="button"
                        className="navbar-toggle collapsed"
                        data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                        aria-expanded="false"
                    >
                        <span/>
                    </button>

                    <a className="navbar-brand" href="">
                        <img className="img-responsive" src={logo} alt="Logo Oncruiter"/>
                    </a>
                </div>

                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul className="nav navbar-nav">
                        <li><a className="scroll about" href="#about">{translation.header.about}</a></li>
                        <li><a href="#contact"
                               className="scroll contact contact-border">{translation.header.contact}</a></li>
                        <li className="display-none-mobile">
                            <a onClick={setLanguageEn}
                               className={`${language === 'en' ? 'highlight-language' : ''} lang-en`}>EN</a></li>
                        <li className="display-none-mobile">
                            <a onClick={setLanguageNo}
                               className={`${language === 'no' ? 'highlight-language' : ''} lang-no`}>NO</a></li>
                    </ul>
                </div>
            </div>
            {isMenuOpen && <MenuMobile closeMenu={handleCloseMenu}/>}
        </nav>
    )
}

export default Header;