import './description.scss';
import {useContext} from "react";
import {LanguageContext} from "../App";

const Description = () => {
    const {translation} = useContext(LanguageContext);

    return (
        <div id="about" className="second-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="red-description">
                            <p>{translation.description.shortDescription}</p>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <div className="normal-description">
                            <p className="bold">{translation.description.longDescriptionFirst}</p>
                            <p className="normal">{translation.description.longDescriptionSecond}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Description;