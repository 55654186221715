import {useEffect, useState} from "react";

export const useCookies = () => {
    const [cookiesConsent, setCookiesConsent] = useState('');
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const localCookiesConsent = localStorage.getItem('cookiesConsent')

        if(localCookiesConsent) {
            setCookiesConsent(localCookiesConsent);
            setShowBanner(false);
        } else {
            setShowBanner(true);
        }
    }, []);

    const acceptCookies = () => {
        setCookiesConsent('true');
        setShowBanner(false);
        localStorage.setItem("cookiesConsent", "true");
    }

    const denyCookies = () => {
        setCookiesConsent('false');
        setShowBanner(false);
        localStorage.setItem("cookiesConsent", "false");
        localStorage.setItem("preferredLanguage", "en");
    }

    return {
        showBanner,
        cookiesConsent,
        acceptCookies,
        denyCookies,
    }
}