import logo from "../images/logo.png";
import Arrow from "../svg/arrow-left.svg";

export const LegalHeader = () => {
    return (
        <div className='legal-header'>
            <a href='/'>
                <img
                    src={Arrow}
                    alt={'arrow'}
                    style={{height: '100px', width: '100px'}}
                />
            </a>
            <img src={logo} alt="Logo Oncruiter" style={{height: '83px'}}/>
            <div style={{height: '100px', width: '100px'}}/>

        </div>
    )
}

export default LegalHeader;