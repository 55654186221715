import Description from "./Description";
import Founder from "./Founder";

const About = () => {
    return (
        <>
            <Description />
            <Founder />
        </>
    )
}

export default About;