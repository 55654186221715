import './founder.scss';
import founder from '../images/founder.png'
import {useContext} from "react";
import {LanguageContext} from "../App";

const Founder = () => {
    const {translation} = useContext(LanguageContext);

    return (
        <div className="founder-section">
            <div className="founder-container">
                <div>
                    <div className="circle-founder" style={{ backgroundImage: `url('${founder}')`}}>
                    </div>
                    <h2>Oddbjørg Reitan Nørbech</h2>
                    <p>Founder</p>
                </div>
            </div>
            <div className="description-container">
                <div className="founder-description">
                    <p>{translation.founder.vision}</p>
                </div>

            </div>
        </div>
    )
}

export default Founder;